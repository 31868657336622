import { render, staticRenderFns } from "./generic-form.vue?vue&type=template&id=42569486&scoped=true"
import script from "./generic-form.vue?vue&type=script&lang=js"
export * from "./generic-form.vue?vue&type=script&lang=js"
import style0 from "./generic-form.vue?vue&type=style&index=0&id=42569486&prod&lang=scss"
import style1 from "./generic-form.vue?vue&type=style&index=1&id=42569486&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../../../shared/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42569486",
  null
  
)

export default component.exports