<script>
import { fandomBasicMixin } from "src/modules/fandom_mixins_module.js";
import { interactionMixin } from "src/modules/interactions_module.js";
import { generateToken } from 'src/modules/recaptcha_v3_module.js';
import { formAutocompleteInputMixin } from "../../modules/cinque_stagioni_mixins_module.js";
import { get, range, intersection, debounce } from "lodash";

export default {
  mixins: [fandomBasicMixin, interactionMixin, formAutocompleteInputMixin],
  props: {
    action: {
      type: String,
      default: '/upload_simple_form'
    }
  },
  data: function () {
    return {
      form: {
        success: false,
        errors: [],
        loading: false,
        progress: null,
        data: {},
      },
      formFields: [],
      formSubmitted: false,
      contentNameToField: {},
      isPrecompiling: false
    };
  },
  methods: {
    getYearsSelectValues () {
      const currentYear = (new Date()).getFullYear();
      return range(1900, currentYear);
    },
    getButtonTextFormatted () {
      if (this.form.loading) {
        return this.ft("globals.form.loading");
      } else {
        return this.content.button_text ? this.content.button_text : this.ft("globals.form.send");
      }
    },
    getFieldInputName(fieldName) {
      return `user_upload_interaction[${fieldName}]`;
    },
    getFieldInputId(fieldName) {
      return `field-${fieldName}`;
    },
    getFieldPlaceholder(field) {
      let placeholder = (typeof field.placeholder == "object") ? field.placeholder[globalState.lang] : field.placeholder;
      placeholder = placeholder ? placeholder : "";
      return field.required && placeholder !== "" ? `${placeholder} *` : placeholder;
    },
    getFieldLabel(field) {
      return (typeof field.label == "object") ? field.label[globalState.lang] : field.label;
    },
    getMultipleChoiceText(choice) {
      return (typeof choice == "object") ? choice[globalState.lang] : choice;
    },
    getFieldDescription(field) {
      return (typeof field.description == "object") ? field.description[globalState.lang] : field.description;
    },
    onAttachmentChange(event, field) {
      Vue.set(this.form.data, field.name, event.target.files[0]);
    },
    submitSimpleForm(event) {
      // In case of Salesforce integration a proprietary action is passed as argument and the form works with a classic submission form.
      if (this.content.action) {
        // nothing to do
      } else {
        event.preventDefault();
        if (!this.form.loading) {
          this.form.success = false;
          this.form.loading = true;
          this.form.errors = null;

          let submitForm = (token) => { 
            const data = this.getFormData();
            if (token)
              data.append("recaptcha_enterprise_token", token);

            Fandom.ajax({
                processData: false,
                contentType: false,
                method: "POST",
                url: this.action,
                data: data,
                xhr: () => {
                  const xhr = new window.XMLHttpRequest();
                  xhr.upload.addEventListener("progress", (evt) => {
                    if (evt.lengthComputable) {
                      this.progress = evt.loaded / evt.total * 100;
                    }
                  }, false);
                  return xhr;
                },
                success: (data) => {
                  this.formSubmitted = true;
                  this.form.success = Fandom.exists(data.success) && data.success;
                  if (this.form.success) {
                    if (Fandom.exists(this.content.thank_you_page)) {
                      window.location.href = Fandom.applyContextToUrl(this.content.thank_you_page);
                    } else if (!this.content.precompile) {
                      this.resetFormData();      
                    }
                  }
                },
              },
              () => {
                this.progress = null;
                this.form.loading = false;
              }
            );
          };

          if (globalState.pageModel.aux.recaptcha_enterprise) {
            generateToken(submitForm, "submit");
          } else {
            submitForm();
          }
        }
      }
    },
    mayPrecompileFormData() {
      if (this.content.precompile) {
        Fandom.ajax({
          method: "GET",
          data: {
            interaction_id: this.content.interaction.id,
            container_content_name: this.containerContent.name
          },
          url: "/api/v5/get_submission_init_data",
          success: (data) => {
            this.isPrecompiling = true;
            this.formFields.forEach(field => {
              const value = data[field.name];
              if (Fandom.exists(value)) {
                switch (field.type) {
                  case "birthdate":
                    let date = new Date(value);
                    date = { year: date.getFullYear(), month: (date.getMonth() + 1), day: date.getDate() };

                    ["day", "month", "year"].forEach(prefix => {
                      Vue.set(this.form.data, `${prefix}_${field.name}`, date[prefix]);
                    });
                    break;
                  case "autocomplete":
                    const data = value.startsWith("{") ? JSON.parse(value)[this.currentLang] : value;
                    Vue.set(this, "autocompleteSelectedContent", { title: data });
                    Vue.set(this.form.data, field.name, { title: data });
                    break;
                  case "multiple-choice":
                    const choices = JSON.parse(value);
                    Vue.set(this.form.data, field.name, choices);
                    break;
                  case "map":
                    break;
                  case "attachment":
                    break;
                  case "checkbox":
                    Vue.set(this.form.data, field.name, (value.toLowerCase == "true" ? true : false));
                    break;
                  default:
                    Vue.set(this.form.data, field.name, value);
                }
              }
            });
            Vue.nextTick(() => {
              this.isPrecompiling = false;
            });
          }
        });
      }
    },
    resetFormData() {
      this.form.data = {};
      this.mayInitializeFields();
    },
    getFormData () {
      const formData = new FormData();
      this.formFields.forEach(field => {
        if (field.type == "birthdate") {
          const data = { year: null, month: null, day: null };
          ["day", "month", "year"].forEach(prefix => {
            data[prefix] = this.form.data[`${prefix}_${field.name}`];
          });
          if (data.year && data.month && data.day) {
            const month = ("0" + data.month).slice(-2);
            const day = ("0" + data.day).slice(-2);
            formData.append(this.getFieldInputName(field.name), `${data.year}/${month}/${day}`);
          }
        } else if (field.type === "autocomplete" || field.type === "multiple-choice") {
          const formDataValue = JSON.stringify(this.form.data[field.name]);
          formData.append(this.getFieldInputName(field.name), formDataValue);
        } else {
          const formDataValue = this.form.data[field.name];
          if (Fandom.exists(formDataValue) && field.type != "textseparator") {
            formData.append(this.getFieldInputName(field.name), formDataValue);
          }
        }
      });

      formData.append("interaction_id", this.content.interaction.id);
      formData.append("content_id", this.containerContent.id);

      return formData;
    },
    mayInitializeFields() {
      this.formFields.forEach((field) => {
        switch (field.type) {
          case "hidden":
            Vue.set(this.form.data, field.name, field.value);
            break;
          case "dropdown": // Dropdown required default empty string
            Vue.set(this.form.data, field.name, "");
            break;
          case "birthdate": // Dropdown required default empty string
            ["day", "month", "year"].forEach(prefix => {
              Vue.set(this.form.data, `${prefix}_${field.name}`, "");
            });
            break;
          case "content":
            Vue.set(this.contentNameToField, field.content_name, []);
            Vue.set(this.form.data, field.name, ""); // Dropdown required default empty string
            break;
          case "multiple-choice":
            Vue.set(this.form.data, field.name, []);
            break;
          case "autocomplete":
            Vue.set(this.form.data, field.name, { title: "" });
            break;
          default:
            // nothing to do
        }
      });

      if (!!Object.keys(this.contentNameToField).length) {
        Fandom.post({
            url: "/api/v5/get_submission_contents_from_content_fields",
            data: { 
              layout: "system-refs",
              layout_extra_field_name: "$empty",
              content_filtering: [Object.keys(this.contentNameToField)]
            },
            success: (data) => {
              data.name_to_content[data.main_content_name].children.forEach(contentName => {
                const content = data.name_to_content[contentName];
                intersection(content.parent_names, Object.keys(this.contentNameToField)).forEach(parentContent => {
                  this.contentNameToField[parentContent].push(content);
                });
              });
            }
          }
        );
      }
    },
    toggleMultipleChoice(field, choice) {
      Vue.set(this.form.data, field.name, get(this, ['form','data',field.name], []));
      const index = this.form.data[field.name].findIndex(c => c.id == choice.id)
      if (index > -1) {
        this.form.data[field.name].splice(index, 1);
      } else {
        this.form.data[field.name].push(choice);
      }
    }
  },
  created() {
    Vue.set(this, 'formFields', JSON.parse(this.content.form_fields).fields);
    this.mayInitializeFields();
    if (!this.content.action) {
      this.mayPrecompileFormData();
    }
  },
  mounted() {
    const component = this;
    Vue.nextTick(() => {
      this.formFields.filter(f => f.hide_field && f.hide_value).forEach(f => {
        const fieldIndex = component.formFields.findIndex(f2 => f2.name === f.hide_field);
        $(this.$refs[f.name]).change(debounce(() => {
          Vue.set(component.formFields[fieldIndex], 'hide', !(f.hide_value === $(this.$refs[f.name]).val()));
          if (this.formFields[fieldIndex].hide && this.formFields[fieldIndex].default_value) {
            Vue.set(this.form.data, f.hide_field, this.formFields[fieldIndex].default_value);
          }
        }, 200));
      });
    });
  },
  computed: {
    currentLang() {
      return get(globalState, "lang", "it");
    },
    isFormLoading() {
      return this.form.loading || (Fandom.exists(this.form.progress) && parseInt(this.form.progress) < 100);
    },
    activeFields() {
      return this.formFields.filter(field => !field.hide);
    },
    hiddenFields() {
      return this.formFields.filter(field => field.hide && field.default_value);
    },
  }
};
</script>

<template>
  <div generic-form-component>
    <form class="d-flex flex-column" @submit="submitSimpleForm($event)" :action="content.action || action" method="post" :enctype="content.action ? null : 'multipart/form-data'">
      <div v-if="content.action" style="position:absolute; left:-9999px; top: -9999px;">
        <label for="pardot_extra_field">Comments</label>
        <input type="text" id="pardot_extra_field" name="pardot_extra_field">
      </div>
      <div v-for="(field, index) in activeFields" :key="index">
        <div v-if="field.type == 'dropdown'" class="form-group">
          <label class="form-label" v-if="getFieldLabel(field)">
            {{getFieldLabel(field)}} <span v-if="field.required">*</span>
          </label>
          <div class="custom-select-outer">
            <select class="custom-select" :ref="field.name" :required="field.required" :name="field.name" v-model="form.data[field.name]">
              <option value="" disabled selected>{{getFieldPlaceholder(field)}}</option>
              <option v-for="(option, optionIndex) in field.options" :key="`option-${optionIndex}`" :value="option.value">{{getFieldLabel(option)}}</option>
            </select>
          </div>
        </div>
        <div v-if="field.type == 'content'" class="form-group">
          <label class="form-label" v-if="getFieldLabel(field)">
            {{getFieldLabel(field)}} <span v-if="field.required">*</span>
          </label>
          <div class="custom-select-outer">
            <select class="custom-select" :ref="field.name" :required="field.required" :name="field.name" v-model="form.data[field.name]">
              <option value="" disabled selected>{{getFieldPlaceholder(field)}}</option>
              <option v-for="(option, optionIndex) in contentNameToField[field.content_name]" :key="`option-${optionIndex}`" :value="option.name">{{option.title}}</option>
            </select>
          </div>
        </div>
        <div v-else-if="field.type == 'textfield' || field.type == 'fiscalcode'" class="form-group">
          <label class="form-label" v-if="getFieldLabel(field)">
            {{getFieldLabel(field)}} <span v-if="field.required">*</span>
          </label>
          <input :ref="field.name" :name="field.name" v-model="form.data[field.name]" :placeholder="getFieldPlaceholder(field)" class="form-control" type="text" :required="field.required">
        </div>
        <div v-else-if="field.type == 'password'" class="form-group">
          <label class="form-label"  v-if="getFieldLabel(field)">
            {{getFieldLabel(field)}} <span v-if="field.required">*</span>
          </label>
          <input :ref="field.name" :name="field.name" v-model="form.data[field.name]" placeholder="**********" class="form-control" type="password" :required="field.required" :pattern="field.pattern || '(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$'">
        </div>
        <div v-else-if="field.type == 'phone'" class="form-group">
          <label class="form-label" v-if="getFieldLabel(field)">
            {{getFieldLabel(field)}} <span v-if="field.required">*</span>
          </label>
          <input :ref="field.name" :name="field.name" v-model="form.data[field.name]" :placeholder="getFieldPlaceholder(field)" class="form-control" type="number" :required="field.required">
        </div>
        <div v-else-if="field.type == 'textarea'" class="form-group">
          <label class="form-label" v-if="getFieldLabel(field)">
            {{getFieldLabel(field)}} <span v-if="field.required">*</span>
          </label>
          <textarea :ref="field.name" :name="field.name" v-model="form.data[field.name]" :placeholder="getFieldPlaceholder(field)" class="form-control" rows="5" :maxlength="field.maxlength || 1000"></textarea>
        </div>
        <div v-else-if="field.type == 'radio'" class="text-left checkbox">
          <label class="form-label" v-if="getFieldLabel(field)">
            {{getFieldLabel(field)}} <span v-if="field.required">*</span>
          </label>
          <p v-if="getFieldDescription(field)" v-html="getFieldDescription(field)"></p>
          <div class="form-group">
            <span class="radio-option mx-2" v-for="(option, optionIndex) in field.options" :key="`option-${optionIndex}`">
              <label
                class="custom-checkbox gold border d-inline-flex align-items-center justify-content-center p-1 cursor-pointer mr-2 rounded-circle text-white"
                :for="`${field.name}-${optionIndex}`"
                :class="{'active': form.data[field.name] === option.value}"
              >
                <input
                  :ref="field.name"
                  type="radio"
                  :name="field.name"
                  :value="option.value"
                  v-model="form.data[field.name]"
                  :id="`${field.name}-${optionIndex}`"
                  :required="field.required"
                  class="radio-input"
                >
                <i class="fas fa-circle"></i>
              </label>
              <label class="form-label">{{getFieldLabel(option)}}</label>
            </span>
          </div>
        </div>
        <div v-else-if="field.type == 'checkbox'" class="form-group checkbox d-flex align-items-start">
          <input :ref="field.name" type="checkbox" class="d-none" :id="field.name" :name="field.name" v-model="form.data[field.name]">
          <label
            class="custom-checkbox gold border d-inline-flex align-items-center justify-content-center p-1 cursor-pointer mr-2 mt-2 rounded-circle text-white"
            :for="field.name"
            :class="{'active': form.data[field.name]}"
            >
            <i class="fas fa-check"></i>
          </label>
          <label class="form-check-label">
            <span v-html="getFieldLabel(field)"></span> <span v-if="field.required">*</span>
          </label>
        </div>
        <div v-else-if="field.type == 'attachment'" class="form-group" :class="{'mt-3': field.style == 'button'}">
          <label class="form-label" v-if="getFieldLabel(field) && field.style != 'button'">
            {{getFieldLabel(field)}} <span v-if="field.required">*</span>
          </label>
          <div class="custom-file">
            <input :ref="field.name" :name="field.name" type="file" :class="{'h-100': field.style == 'button'}" class="custom-file-input cursor-pointer" @change="onAttachmentChange($event, field)">
            <div class="position-absolute-center d-flex flex-column justify-content-center align-items-center" v-if="field.style == 'button'">
              <button class="btn btn-primary">{{getFieldPlaceholder(field)}}</button>
              <div class="d-flex mt-3 align-items-center" v-if="form.data[field.name] && form.data[field.name].name">
                <i class="far fa-check-circle text-success mr-2"></i><small class="form-text text-muted">{{form.data[field.name].name}}</small>
              </div>
            </div>
            <template v-else>
              <label class="custom-file-label">{{getFieldPlaceholder(field)}}</label>
              <small class="form-text text-muted" v-if="form.data[field.name] && form.data[field.name].name">{{form.data[field.name].name}}</small>
            </template>
          </div>
        </div>
        <div v-else-if="field.type == 'email'" class="form-group">
          <label class="form-label" v-if="getFieldLabel(field)">
            {{getFieldLabel(field)}} <span v-if="field.required">*</span>
          </label>
          <input :ref="field.name" :name="field.name" v-model="form.data[field.name]" :placeholder="getFieldPlaceholder(field)" class="form-control" type="email" :required="field.required">
        </div>
        <input-google-maps
          v-else-if="field.type == 'map'"
          @set-location="(data) => {
            form.data[field.name] = data;
          }"
        />
        <div v-else-if="field.type == 'address'" class="form-group">
          <label class="form-label" v-if="getFieldLabel(field)">
            {{getFieldLabel(field)}} <span v-if="field.required">*</span>
          </label>
          <input-address-google-maps
            :name="field.name"
            :input-name="field.name"
            :input-required="field.required"
            :input-id="`input_${field.name}`"
            :initial-value="form.data[field.name]"
            :input-placeholder="field.placeholder[globalState.lang]"
            @set-address="(data) => {
              form.data[field.name] = data;
            }"
          />
        </div>
        <div v-else-if="field.type == 'birthdate'" class="form-group">
          <label class="form-label" v-if="getFieldLabel(field)">
            {{getFieldLabel(field)}} <span v-if="field.required">*</span>
          </label>
          <div class="row">
            <div class="col-12 col-sm-4">
              <div class="custom-select-outer">
                <select :ref="field.name" class="custom-select" :name="field.name" v-model="form.data[`day_${field.name}`]" :required="field.required">
                  <option value="" disabled selected>{{ft("globals.form.day")}}</option>
                  <option v-for="day in 31" :value="day" :key="`day-${day}`">{{("0" + day).slice(-2)}}</option>
                </select>
              </div>
            </div>
            <div class="col-12 col-sm-4">
              <div class="custom-select-outer">
                <select :ref="field.name" class="custom-select" :name="field.name" v-model="form.data[`month_${field.name}`]" :required="field.required">
                  <option value="" disabled selected>{{ft("globals.form.month")}}</option>
                  <option v-for="month in 12" :value="month" :key="`month-${month}`">{{("0" + month).slice(-2)}}</option>
                </select>
              </div>
            </div>
            <div class="col-12 col-sm-4">
              <div class="custom-select-outer">
                <select :ref="field.name" class="custom-select" :name="field.name" v-model="form.data[`year_${field.name}`]" :required="field.required">
                  <option value="" disabled selected>{{ft("globals.form.year")}}</option>
                  <option v-for="year in getYearsSelectValues()" :value="year" :key="`year-${year}`">{{year}}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="field.type === 'autocomplete'" class="form-group">
          <label class="form-label" v-if="getFieldLabel(field)">
            {{getFieldLabel(field)}} <span v-if="field.required">*</span>
          </label>
          <template>
            <div class="text-danger small mb-2" v-if="autocompleteErrors.length > 0">
              <div v-for="(error, index) in autocompleteErrors" :key="`error-${index}`">{{error}}</div>
            </div>
            <div class="dropdown" :id="`field-${field.name}`" data-toggle="dropdown" aria-expanded="false">
              <input
                @blur="autocompleteValidateSelection(field, form.data[field.name], 'title')"
                @focus.once="debounceAutocomplete(field, form.data[field.name].title)"
                @keyup="debounceAutocomplete(field, form.data[field.name].title)"
                :placeholder="getMLInfo(field.placeholder)"
                v-model="form.data[field.name].title"
                @keypress.enter.stop.prevent=""
                :name="field.name"
                :required="field.required"
                data-toggle="dropdown"
                aria-expanded="false"
                class="form-control"
                autocomplete="off"
                :ref="field.name"
                type="text"
              />
              <div class="dropdown-menu" v-if="autocompleteErrors.length === 0" :aria-labelledby="`user_${field.name}`">
                <a
                  class="dropdown-item small"
                  href="#"
                  v-for="(item, index) in autocompleteContents"
                  :key="`autocomplete-${field.name}-${index}`"
                  @keyup.prevent="autocompleteItemClicked(item)"
                  @click.prevent="autocompleteItemClicked(item)"
                >{{item.title}}</a>
                <a
                  v-if="field.mandatory_option_name"
                  class="dropdown-item small"
                  href="#"
                  @keyup.prevent="autocompleteItemClicked({name: field.mandatory_option_name, title: field.mandatory_option_title, simpleFormField: field.name})"
                  @click.prevent="autocompleteItemClicked({name: field.mandatory_option_name, title: field.mandatory_option_title, simpleFormField: field.name})"
                >
                  {{getMLInfo(field.mandatory_option_title) || getMLInfo(field.mandatory_option_name)}}
                </a>
              </div>
            </div>
          </template>
        </div>
        <div v-html="field.value[globalState.lang]" class="py-4" v-else-if="field.type === 'HTML'"/>
        <div v-else-if="field.type == 'multiple-choice'" class="form-group">
          <label v-if="getFieldLabel(field)" class="form-label">{{getFieldLabel(field)}} <span v-if="field.required">*</span></label>
          <input type="hidden" :name="field.name" :value="form.data[field.name].map(oggetto => oggetto.id)">
          <div class="d-flex flex-wrap">
            <div class="form-group checkbox mr-3 pr-3 pl-0" v-for="(choice, index) in field.choices" :key="`choice-${index}`">
              <label
                class="custom-checkbox red border d-inline-flex align-items-center justify-content-center p-1 cursor-pointer mr-2 rounded-circle"
                :for="`choice-${field.name}-${index}`"
                :class="{'active': form.data[field.name] instanceof Array && form.data[field.name].find(c => choice.id == c.id)}"
              >
                <input
                  class="radio-input"
                  type="checkbox"
                  :ref="field.name"
                  :id="`choice-${field.name}-${index}`"
                  @click="toggleMultipleChoice(field, choice)"
                  :checked="form.data[field.name] instanceof Array && form.data[field.name].find(c => choice.id == c.id)"
                  :required="field.required && form.data[field.name].length == 0"
                >
                <i class="far fa-check text-white"></i>
              </label>
              <label class="form-check-label" v-html="getMultipleChoiceText(choice)"></label>
            </div>
          </div>
        </div>

        <p v-if="field.type == 'textseparator'">{{getFieldLabel(field)}}</p>
      </div>

      <div class="d-flex justify-content-center align-items-center justify-content-md-between pt-5 flex-column flex-md-row">
        <div class="d-flex flex-column text-center text-md-left pt-4 pt-md-0">
          <p v-html="content.privacy_disclaimer" v-if="content.privacy_disclaimer"></p>
          <p>{{ft("globals.form.required_fields")}}</p>
        </div>

        <button type="submit" class="btn" :class="[getPrimaryButtonClass(content.button_class), {'disabled': form.loading}]" :disabled="form.loading">
          {{getButtonTextFormatted()}}
        </button>
      </div>

      <div class="progress" v-if="form.progress">
        <div class="progress-bar" role="progressbar" :aria-valuenow="form.progress" aria-valuemin="0" aria-valuemax="100" :style="{ width: form.progress + '%' }">
          <span class="progress-bar-text">{{ft("globals.form.uploading", {percentage: form.progress})}}</span>
        </div>
      </div>

      <form-feedback v-if="formSubmitted" :errors="form.errors" :success="form.success" :success-message="ft('globals.form.success')"></form-feedback>
    </form>
  </div>
</template>

<style lang="scss">
[generic-form-component] {
  .v-autocomplete-input-group {
    width: 100%;
    height: 100%;

    .v-autocomplete-input {
      border: none;
      box-shadow: none;
      width: 100%;
      height: 100%;
      outline: none !important;
    }
  }

  .v-autocomplete-list {
    top: 4.0625rem;
    left: 0;
    right: 0;
    outline: 1px solid $gray-300;
    z-index: $zindex-dropdown;

    .v-autocomplete-list-item {
      background-color: $white;
    }
  }
}
</style>

<style lang="scss" scoped>
  .custom-select-outer {
    width: 100%;
  }

  .progress-bar-text {
    position: absolute;
    left: 0;
    right: 0;
  }

  .radio-input {
    position: absolute;
    z-index: -1;
  }

  @include media-breakpoint-down(sm) {
    .custom-file-label,
    .custom-file-input {
      height: 200%;
      overflow: hidden;
    }

    .custom-file-label {
      padding-top: 0.2rem;
      padding-left: 0.2rem;
    }

    .custom-file-label::after {
      bottom: 0;
      top: auto;
      width: 100%;
      justify-content: center;
      display: flex;
    }
  }
</style>
